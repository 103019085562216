import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { InlineIcon } from "@iconify/react";
import { Close } from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  Badge,
  Button,
  Collapse,
  Grow,
  IconButton,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import "./Favorite.styles.scss";
import ListManager from "@/components/ListManager/ListManager";
import { apiGetFavorite, apiRemoveFromFavorite } from "../../api/favorite.api";
import { Toastify } from "@/utils/toast";
import { TProfileCard } from "../../Elements/ProfileCard/ProfileCard";
import * as _ from "lodash";
import { updateFavoriteNotif } from "@/store/reducers/member/member.actions";
import { store } from "@/store";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import useUserRestrictions from "@/hooks/useUserRestriction";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";
import { TProfileList } from "../Home-content/Home.content";
import { calculerAge } from "@/utils/calculerAge";
import { TMemberProfile } from "@/features/auth/types";
import {
  selectMemberProfile,
  selectMessageNotif,
} from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import NoData from "@/components/No-data/NoData";
import { getMeUpdate } from "../../initialize_param/get_me_update";
import { routePaths } from "@/config";
import { updateOpenDiscussion } from "@/store/reducers/general_param/general_param.actions";
import useExchangeRole from "@/hooks/useExchangeRole";
import { useNavigate } from "react-router-dom";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
}));

export const FavoriteContext = createContext<{
  removeFromFavoriteFct: (profil_id: string, username: string) => void;
  loadingRemove: boolean;
}>({
  removeFromFavoriteFct: () => {},
  loadingRemove: false,
});

const CtFavorite = () => {
  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const {
    isUserFullAccessAutorized,
    isUserInExchange,
    isUserCloseExchangeInProgress,
  } = useUserRestrictions();

  const nbre_message_notif = useSelector(selectMessageNotif);
  const navigate = useNavigate();
  const { receiver } = useExchangeRole();

  const [openAlert, setOpenAlert] = useState(false);
  const handleOpenAlert = () => setOpenAlert(true);
  const handleCloseAlert = () => setOpenAlert(false);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);

  const [listeFavorite, setListeFavorite] = useState<TProfileCard[]>([]);
  const [listeInterestedInMe, setListeInterestedInMe] = useState<
    TProfileCard[]
  >([]);

  const [navState, setNavState] = React.useState<"for_me" | "from_them">(
    "for_me"
  );

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newNavState: "for_me" | "from_them"
  ) => {
    setNavState(newNavState);
  };

  const { socketIO } = useContext(AppSocketContext);

  useEffect(() => {
    store.dispatch(updateFavoriteNotif(0));

    if (isUserInExchange || isUserCloseExchangeInProgress) {
    } else {
      isUserFullAccessAutorized && setLoading(true);
      setLoading2(true);

      isUserFullAccessAutorized &&
        apiGetFavorite()
          .then((response: any) => {
            setLoading(false);
            setLoading2(false);
            console.log(response);

            let tempList1: TProfileList = response.data.result.favorites.map(
              (data: any) => {
                return {
                  _id: data._id,
                  isVerified: data.status === "VALIDATED",
                  username: data.username,
                  type: "",
                  progressValue: Math.round(data.rateOfCompletion),
                  profil_id: data.memberId,
                  isOnline: data?.online,
                  age: calculerAge(new Date(data.birthDate)),
                  town: data.city,
                  country: data.country,
                  origine: data.homeCountry,
                  nationalite: data.citizenship,
                  hasMore: false,
                  sendStatut: "",
                  description: data.description,
                  profile_wanted: data.profilewanted,
                  currentConversation: data.currentConversation,
                  isMutualFavorite: response.data.result.fans.some(
                    (x: any) => x._id === data._id
                  ),
                  isSimpleFavorite: true,
                  date_of_creation: new Date(data.registrationDate),
                  last_connection:
                    data.lastLogin !== null ? new Date(data.lastLogin) : null,
                  isVisited: data.isVisited,
                  isVisitor: data.isVisitor,
                  hasSubscription: data.hasSubscription,
                  gender:
                    memberProfileFromStore.infos.identity.gender === "MALE"
                      ? "FEMALE"
                      : "MALE",
                  is_banished: false,
                };
              }
            );

            let tempList2: TProfileList = response.data.result.fans.map(
              (data: any) => {
                return {
                  _id: data._id,
                  isVerified: data.status === "VALIDATED",
                  username: data.username,
                  type: "",
                  progressValue: Math.round(data.rateOfCompletion),
                  profil_id: data.memberId,
                  isOnline: data?.online,
                  age: calculerAge(new Date(data.birthDate)),
                  town: data.city,
                  country: data.country,
                  origine: data.homeCountry,
                  nationalite: data.citizenship,
                  hasMore: false,
                  sendStatut: "",
                  description: data.description,
                  profile_wanted: data.profilewanted,
                  currentConversation: data.currentConversation,
                  isMutualFavorite: response.data.result.favorites.some(
                    (x: any) => x._id === data._id
                  ),
                  isSimpleFavorite: true,
                  date_of_creation: new Date(data.registrationDate),
                  last_connection:
                    data.lastLogin !== null ? new Date(data.lastLogin) : null,
                  isVisited: data.isVisited,
                  isVisitor: data.isVisitor,
                  hasSubscription: data.hasSubscription,
                  gender:
                    memberProfileFromStore.infos.identity.gender === "MALE"
                      ? "FEMALE"
                      : "MALE",
                  is_banished: false,
                };
              }
            );

            setListeFavorite([...tempList1]);
            setListeInterestedInMe([...tempList2]);

            // store.dispatch(updateListFavoris([...tempList1]));
            // store.dispatch(updateListFans([...tempList2]));
          })
          .catch((error) => {
            setLoading(false);
            setLoading2(false);
            if (false) {
              console.log(error);
              error?.response?.data?.message &&
                Toastify(`${error?.response?.data?.message}`, "error", 5000);
            }
          });
    }

    return () => {
      store.dispatch(updateFavoriteNotif(0));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFullAccessAutorized]);

  useEffect(() => {
    //console.log('||| ⭐️',socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.new_favorite_notif, (data) => {
        console.log("||| ⭐️ - channel", socketChannels.new_favorite_notif);
        console.log("||| ⭐️", data);
        const newFans: any = {
          _id: data._id,
          isVerified: data.status === "VALIDATED",
          username: data.username,
          type: "",
          progressValue: Math.round(data.rateOfCompletion),
          profil_id: data.memberId,
          isOnline: data?.online,
          age: calculerAge(new Date(data.birthDate)),
          town: data.city,
          country: data.country,
          origine: data.homeCountry,
          nationalite: data.citizenship,
          hasMore: false,
          sendStatut: "",
          description: data.description,
          profile_wanted: data.profilewanted,
          currentConversation: data.currentConversation,
          isMutualFavorite: listeFavorite.some((x: any) => x._id === data._id),
          isSimpleFavorite: true,
          date_of_creation: new Date(data.registrationDate),
          last_connection:
            data.lastLogin !== null ? new Date(data.lastLogin) : null,
          isVisited: data.isVisited,
          isVisitor: data.isVisitor,
          is_banished: false,
          gender:
            memberProfileFromStore.infos.identity.gender === "MALE"
              ? "FEMALE"
              : "MALE",
        };
        setListeInterestedInMe([{ ...newFans }, ...listeInterestedInMe]);
        setListeFavorite(
          listeFavorite.map((x) => {
            if (x._id === newFans._id) {
              return {
                ...x,
                isMutualFavorite: true,
              };
            } else {
              return x;
            }
          })
        );
      });

      socketIO.on(socketChannels.delete_favorite_notif, (data) => {
        console.log(data);
        const tempArr = listeInterestedInMe;
        const removedItem = _.remove(tempArr, (x) => {
          return x._id === data;
        });
        console.log(removedItem);
        setListeInterestedInMe([...tempArr]);

        setListeFavorite(
          listeFavorite.map((x) => {
            if (x._id === data) {
              return {
                ...x,
                isMutualFavorite: false,
              };
            } else {
              return x;
            }
          })
        );
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, listeInterestedInMe, listeFavorite]);

  const removeFromFavoriteFct = (profil_id: string, username: string) => {
    setLoadingRemove(true);
    apiRemoveFromFavorite(profil_id)
      .then((response: any) => {
        setLoadingRemove(false);
        console.log(response);

        //je mets à jour la liste des favoris
        let tempArr = listeFavorite;
        let removedItem = _.remove(tempArr, (x) => {
          return x._id === profil_id;
        });
        console.log(removedItem);
        setListeFavorite([...tempArr]);

        //je mets à jour la liste des fans
        const findIndex = listeInterestedInMe.findIndex(
          (elt) => elt._id === profil_id
        );
        if (findIndex !== -1) {
          setListeInterestedInMe(
            listeInterestedInMe.map((x) => {
              if (x._id === profil_id) {
                return {
                  ...x,
                  isMutualFavorite: false,
                };
              } else {
                return x;
              }
            })
          );
        }

        Toastify(
          `Vous avez retiré ${username} de vos favoris avec succès`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingRemove(false);
      });
  };

  const removeFavoriteDueToBlacklist = (_id: string) => {
    const tempArr = listeFavorite;
    const removedItem = _.remove(tempArr, (x) => {
      return x._id === _id;
    });
    console.log(removedItem);
    setListeInterestedInMe([...tempArr]);
  };

  const removeFansDueToBlacklist = (_id: string) => {
    const tempArr = listeInterestedInMe;
    const removedItem = _.remove(tempArr, (x) => {
      return x._id === _id;
    });
    console.log(removedItem);
    setListeInterestedInMe([...tempArr]);
  };

  const addMutualFavorite = (_id: string) => {
    setListeInterestedInMe(
      listeInterestedInMe.map((x) => {
        if (x._id === _id) {
          return {
            ...x,
            isMutualFavorite: true,
          };
        } else {
          return x;
        }
      })
    );
    const findIndex = listeInterestedInMe.findIndex((elt) => elt._id === _id);

    console.log(findIndex);
    console.log(listeInterestedInMe[findIndex]);

    if (findIndex !== -1) {
      const tempArr = [
        ...listeFavorite,
        { ...listeInterestedInMe[findIndex], isMutualFavorite: true },
      ];
      console.log(tempArr.length);
      setListeFavorite([...tempArr]);
    }
  };

  const removeMutualFavorite = (_id: string) => {
    setListeInterestedInMe(
      listeInterestedInMe.map((x) => {
        if (x._id === _id) {
          return {
            ...x,
            isMutualFavorite: false,
          };
        } else {
          return x;
        }
      })
    );

    const tempArr = [...listeFavorite];
    const removedItem = _.remove(tempArr, (x) => {
      return x._id === _id;
    });

    console.log(removedItem);
    console.log(tempArr.length);
    setListeFavorite([...tempArr]);
  };

  const updateFavoriteListWhileRemovingFromFavorite = (profil_id: string) => {
    //je mets à jour la liste des favoris
    let tempArr = listeFavorite;
    let removedItem = _.remove(tempArr, (x) => {
      return x._id === profil_id;
    });
    console.log(removedItem);
    setListeFavorite([...tempArr]);

    //je mets à jour la liste des fans
    const findIndex = listeInterestedInMe.findIndex(
      (elt) => elt._id === profil_id
    );
    if (findIndex !== -1) {
      setListeInterestedInMe(
        listeInterestedInMe.map((x) => {
          if (x._id === profil_id) {
            return {
              ...x,
              isMutualFavorite: false,
            };
          } else {
            return x;
          }
        })
      );
    }
  };

  const mutualInterestNumber = useCallback(() => {
    return _.sumBy(listeInterestedInMe, (x) =>
      x.isMutualFavorite === true ? 1 : 0
    );
  }, [listeInterestedInMe]);

  useEffect(() => {
    getMeUpdate();
  }, []);

  return (
    <FavoriteContext.Provider
      value={{
        removeFromFavoriteFct: removeFromFavoriteFct,
        loadingRemove: loadingRemove,
      }}
    >
      <>
        {isUserFullAccessAutorized ? (
          <div className="ct-favorite">
            <div className="ct-favorite__nav-part">
              {!(isUserInExchange || isUserCloseExchangeInProgress) && (
                <div className="tabs-part">
                  <ToggleButtonGroup
                    color="primary"
                    className="yel-toggle-group"
                    value={navState}
                    exclusive
                    aria-label="Platform"
                  >
                    <ToggleButton
                      value="for_me"
                      onClick={(e) => {
                        handleChange(e, "for_me");
                      }}
                    >
                      {memberProfileFromStore?.infos?.identity?.gender ===
                      "MALE"
                        ? "Celles"
                        : "Ceux"}{" "}
                      qui m'intéressent ({listeFavorite.length}){" "}
                    </ToggleButton>
                    <ToggleButton
                      value="from_them"
                      onClick={(e) => {
                        handleChange(e, "from_them");
                      }}
                    >
                      {memberProfileFromStore?.infos?.identity?.gender ===
                      "MALE"
                        ? "Celles"
                        : "Ceux"}{" "}
                      que j'intéresse ({listeInterestedInMe.length}){" "}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              )}
            </div>
            <div className="ct-favorite__body-part">
              {!(isUserInExchange || isUserCloseExchangeInProgress) ? (
                <>
                  <div className="ct-favorite__body-part__upper-part">
                    {matches_sm ? (
                      <>
                        <CustomTooltip title="Bon à savoir">
                          <IconButton
                            style={{
                              background: "var(--ui-tertiary)",
                              height: 30,
                              width: 30,
                            }}
                            size="small"
                            color="secondary"
                            onClick={handleOpenAlert}
                          >
                            <InlineIcon icon="ci:triangle-warning" />
                          </IconButton>
                        </CustomTooltip>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="tertiary"
                          className="btn"
                          onClick={handleOpenAlert}
                          startIcon={
                            <InlineIcon
                              icon="ci:triangle-warning"
                              style={{ fontSize: "18px" }}
                            />
                          }
                        >
                          Bon à savoir
                        </Button>
                      </>
                    )}

                    <div className="kpi">
                      <InlineIcon
                        icon="clarity:favorite-solid"
                        style={{ color: "var(--ui-warning)" }}
                      />
                      <b> {mutualInterestNumber()} </b>
                      <span> intérêt(s) mutuel(s) </span>
                    </div>
                  </div>
                  {openAlert && (
                    <Collapse in={openAlert} style={{ zIndex: 99 }}>
                      <Alert
                        severity="warning"
                        className="ct-favorite__body-part__alert"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleCloseAlert}
                          >
                            <Close fontSize="inherit" />
                          </IconButton>
                        }
                      >
                        <AlertTitle>Informations utiles</AlertTitle>
                        Mettre en favoris{" "}
                        <strong>
                          {" "}
                          n'implique pas une demande de discussion
                        </strong>
                        <br />
                        Celle-ci doit être faite dans la fiche du membre.
                        <br />
                      </Alert>
                    </Collapse>
                  )}

                  <div className="ct-favorite__body-part__list-manager-part">
                    {navState === "for_me" && (
                      <ListManager
                        loading={loading}
                        profileList={listeFavorite}
                        alt={true}
                        refreshList={(id: string) => {
                          removeFavoriteDueToBlacklist(id);
                        }}
                        removeFromFavoritePart={(id: string) => {
                          updateFavoriteListWhileRemovingFromFavorite(id);
                        }}
                      />
                    )}
                    {navState === "from_them" && (
                      <ListManager
                        loading={loading2}
                        profileList={listeInterestedInMe}
                        alt={false}
                        no_corner_option={true}
                        refreshList={(id: string) => {
                          removeFansDueToBlacklist(id);
                        }}
                        addMutual={(id: string) => {
                          addMutualFavorite(id);
                        }}
                        removeMutual={(id: string) => {
                          removeMutualFavorite(id);
                        }}
                        fromFan={true}
                      />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <NoData />
                  {isUserInExchange && (
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1.2rem",
                      }}
                    >
                      {" "}
                      Vous êtes actuellement en <b>échange</b>
                      {receiver?.username && (
                        <>
                          {" "}
                          avec{" "}
                          <b style={{ color: "var(--ui-primary)" }}>
                            {receiver?.username}
                          </b>
                        </>
                      )}
                      . <br />
                      Vous ne pouvez pas avoir accès aux autres profils <br />
                      <br />
                      <Button
                        variant="contained"
                        onClick={() => {
                          store.dispatch(updateOpenDiscussion(true));
                          navigate(
                            routePaths.profileChildren.exchangeAbsolute,
                            {
                              replace: true,
                            }
                          );
                        }}
                        startIcon={
                          <InlineIcon icon="bx:message-rounded-detail" />
                        }
                        color="primary"
                        style={{
                          width: "fit-content",
                          fontSize: "1rem",
                          padding: "10px 20px",
                        }}
                      >
                        <>Accéder à l'échange</>
                        {nbre_message_notif > 0 && (
                          <>
                                
                            <StyledBadge
                              overlap="circular"
                              variant="standard"
                              color="warning"
                              badgeContent={nbre_message_notif}
                            />
                          </>
                        )}
                      </Button>
                    </p>
                  )}
                  {isUserCloseExchangeInProgress && (
                    <p
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1.2rem",
                      }}
                    >
                      {" "}
                      Votre clôture d'échange est actuellement en{" "}
                      <b>examination</b> auprès de nos modérateurs. <br />
                      Vous ne pouvez pas avoir accès aux autres profils{" "}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
              <div className="ct-favorite">
                <UserRestrictionDisplayer />
              </div>
            </Grow>
          </>
        )}
        {false && <ModalUserRestrictionWhenPaid />}
      </>
    </FavoriteContext.Provider>
  );
};

export default CtFavorite;
