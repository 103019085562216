import { InlineIcon } from "@iconify/react";
import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ListManager.scss";
import classnames from "classnames";
import "moment/locale/fr";
import { TProfileList } from "@/features/profile/pages/Home-content/Home.content";
import ProfileCard from "@/features/profile/Elements/ProfileCard/ProfileCard";
import NoData from "../No-data/NoData";

type TListManager = {
  title?: string;
  subtitle?: string;
  profileList?: TProfileList;
  isForExchange?: boolean;
  isInProgress?: boolean;
  isForCloture?: boolean;
  loading?: boolean;
  alt?: boolean;
  no_corner_option?: boolean;
  refreshList?: (id: string) => void;
  addMutual?: (id: string) => void;
  removeMutual?: (id: string) => void;
  removeFromFavoritePart?: (id: string) => void;
  fromFan?: boolean;
};

type TFilteredValue = "sent" | "received" | "";

const ListManager: React.FC<TListManager> = ({
  title,
  subtitle,
  profileList = [],
  isForExchange = false,
  isInProgress = false,
  isForCloture = false,
  alt = false,
  loading = false,
  no_corner_option = false,
  refreshList,
  addMutual,
  removeMutual,
  removeFromFavoritePart,
  fromFan = false,
}) => {
  const [activeButton, setActiveButton] = useState<TFilteredValue>("");

  const [searchedText, setSearchedText] = useState("");
  const handleSearchChange = (e: any) => {
    setSearchedText(e?.target?.value);
  };

  return (
    <div className="list-manager-container">
      <div className="list-upper-part">
        <TextField
          placeholder="Rechercher..."
          variant="outlined"
          name="recherche"
          className="searchInput-alt"
          type="text"
          value={searchedText}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InlineIcon icon="bi:search" />
              </InputAdornment>
            ),
          }}
        />
        {isInProgress && (
          <>
            <div className="filter-part">
              <div
                className={classnames("msg-filtered-box ", {
                  send: activeButton === "sent",
                })}
                onClick={() => {
                  if (activeButton === "sent") {
                    setActiveButton("");
                  } else {
                    setActiveButton("sent");
                  }
                }}
              >
                <InlineIcon icon="carbon:mail-reply" className="sent" />
                <small>Envoyées</small>
              </div>
              <div
                className={classnames("msg-filtered-box ", {
                  receive: activeButton === "received",
                })}
                onClick={() => {
                  if (activeButton === "received") {
                    setActiveButton("");
                  } else {
                    setActiveButton("received");
                  }
                }}
              >
                <InlineIcon
                  icon="carbon:mail-reply"
                  className="received"
                  flip="horizontal"
                />
                <small>Reçues</small>
              </div>
            </div>
          </>
        )}
      </div>

      {loading && (
        <img
          src={require("@/assets/images/gif/loadmore.gif")}
          height="70"
          width="70"
          className="load-more"
          alt="load-more"
        />
      )}

      {(profileList.length === 0 ||
        profileList
          .filter((value) => {
            const fullname =
              value?.username! +
              value?.age! +
              value?.country +
              value?.town +
              value?.origine +
              value?.nationalite +
              value?.profil_id;
            return (
              fullname
                .toLocaleLowerCase()
                .indexOf(searchedText.toLocaleLowerCase()) !== -1
            );
          })
          .filter((value) => {
            return value?.sendStatut!.indexOf(activeButton) !== -1;
          }).length === 0) &&
        !loading && (
          <div className="list-part no-data">
            <NoData />
          </div>
        )}

      {profileList.length > 0 &&
        profileList.filter((value) => {
          const fullname =
            value?.username! +
            value?.age! +
            value?.country +
            value?.town +
            value?.origine +
            value?.nationalite +
            value?.profil_id;
          return (
            fullname
              .toLocaleLowerCase()
              .indexOf(searchedText.toLocaleLowerCase()) !== -1
          );
        }).length > 0 &&
        !loading && (
          <div className={`list-part ${isForExchange && "isForExchange"}`}>
            <>
              {profileList
                .filter((value) => {
                  const fullname =
                    value?.username! +
                    value?.age! +
                    value?.country +
                    value?.town +
                    value?.origine +
                    value?.nationalite +
                    value?.profil_id;
                  return (
                    fullname
                      .toLocaleLowerCase()
                      .indexOf(searchedText.toLocaleLowerCase()) !== -1
                  );
                })
                .filter((value) => {
                  return value?.sendStatut!.indexOf(activeButton) !== -1;
                })
                .map((value, index) => {
                  return (
                    <ProfileCard
                      key={index}
                      alt={alt}
                      no_corner_option={no_corner_option}
                      isCloture={isForCloture}
                      isExchange={isForExchange}
                      _id={value?._id}
                      conversationId={value?.conversationId}
                      isOnline={value?.isOnline}
                      isVerified={value?.isVerified}
                      hasMore={value?.hasMore}
                      username={value?.username}
                      description={value?.description}
                      profile_wanted={value?.profile_wanted}
                      type={value?.type}
                      age={value?.age}
                      town={value?.town}
                      country={value?.country}
                      origine={value?.origine}
                      profil_id={value?.profil_id}
                      nationalite={value?.nationalite}
                      progressValue={value?.progressValue}
                      sendStatut={value?.sendStatut}
                      isMutualFavorite={value?.isMutualFavorite}
                      isSimpleFavorite={value?.isSimpleFavorite}
                      conversation_id={value?.conversation_id}
                      cloture={value?.cloture}
                      isListeNoire={value?.isListeNoire}
                      has_conversation={value?.has_conversation}
                      is_deleted={value?.is_deleted}
                      date_of_creation={value?.date_of_creation}
                      closure={value?.closure}
                      currentConversation={value?.currentConversation}
                      is_banished={value?.is_banished}
                      hasSubscription={value?.hasSubscription}
                      refreshHome={(type) => {
                        if (type === "add-blacklist") {
                          refreshList?.(value._id || "");
                        }
                        if (type === "remove-favorite") {
                          if (value.isMutualFavorite) {
                            removeMutual?.(value._id || "");
                          } else {
                            refreshList?.(value._id || "");
                          }
                          removeFromFavoritePart?.(value._id || "");
                        }
                        if (type === "add-favorite") {
                          if (!value.isMutualFavorite) {
                            addMutual?.(value._id || "");
                          }
                        }
                      }}
                      fromFan={fromFan}
                    />
                  );
                })}
            </>
          </div>
        )}
    </div>
  );
};

export default ListManager;
