import { SuspenseFallback } from "@/components/SuspenseFallback/SuspenseFallback";
import { routePaths } from "@/config";
import { socketChannels } from "@/config/socketChannels";
import { AppSocketContext } from "@/routes";
import { store, useAppDispatch } from "@/store";
import {
  updateCurrentExchange,
  updateGeneralLinks,
  updateIsModerationSuspend,
  updateIsPaymentPendingProcess,
  updateIsWebsiteMaintenance,
  updateMailFemale,
  updateMailMale,
  updateMaxCaracter,
  updateMaxMessage,
  updateMessageClosedModeration,
  updateModeration,
  updateNbreLiveSocket,
  updateNbreNotificationSocket,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import {
  getMemberInfos,
  getUserConnected,
  updateExchangeNotif,
  updateFavoriteNotif,
  updateIsOnline,
  updateMemberCurrentExchange,
  updateMemberProfile,
  updateMessageNotif,
} from "@/store/reducers/member/member.actions";
import {
  selectExchangeNotif,
  selectFavoriteNotif,
  selectMemberProfile,
  selectMessageNotif,
  selectToken,
} from "@/store/reducers/member/member.selector";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { selectOpened } from "@/store/reducers/sidebar/sidebar.selector";
import classNames from "classnames";
import classnames from "classnames";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { apiGetNotification } from "../api/notification.api";
import Bottombar from "../containers/Bottombar/Bottombar.container";
import SideBar from "../containers/Sidebar/SideBar.container";
import { initializeParam } from "../initialize_param/initialize_param";
import {
  TCurrentExchange,
  TInterfaceSettings,
  TMemberInfos,
  TModerationSocket,
  TUserConnected,
} from "../types";
import "./Profile.styles.scss";
import useUserRestrictions from "@/hooks/useUserRestriction";
import { TMemberProfile } from "@/features/auth/types";
import {
  selectNbreLiveSocket,
  selectNbreNotificationSocket,
} from "@/store/reducers/general_param/general_param.selector";
import { apiPingConnected } from "../api/profile.api";
import { set } from "lodash";
import { Toastify } from "@/utils/toast";
import { apiGetUserConnected } from "@/features/auth/api/auth.api";
import { getMeUpdate } from "../initialize_param/get_me_update";

const LProfile = () => {
  const sidebarOpened = useSelector(selectOpened);
  const token = useSelector(selectToken);
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const { isUserFullAccessAutorized } = useUserRestrictions();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { socketIO, logout } = useContext(AppSocketContext);

  const nbre_message_notif = useSelector(selectMessageNotif);
  const nbre_favorite_notif = useSelector(selectFavoriteNotif);
  const nbre_exchange_notif = useSelector(selectExchangeNotif);
  const nbre_live_notif = useSelector(selectNbreLiveSocket);
  const nbre_notification = useSelector(selectNbreNotificationSocket);
  const location = useLocation();

  useEffect(() => {
    token.length === 0 && navigate(routePaths.auth, { replace: true });
    let callMessageCount = nbre_message_notif;
    let callFavoriteCount = nbre_favorite_notif;
    let callExchangeCount = nbre_exchange_notif;
    let callLiveCount = nbre_live_notif;
    let callNotificationCount = nbre_notification;

    // const { rate_of_completion } = userConnected as TUserConnected;
    // if (rate_of_completion) {
    //   store.dispatch(updateProgress(rate_of_completion));
    // }

    if (socketIO) {
      socketIO.on(socketChannels.profile_updated_me, (data) => {
        console.log("||| 🔥 PROFILE - has changed", data);

        const profile = data as TMemberProfile;
        console.log({ profile });
        store.dispatch(updateMemberProfile({ ...profile }));
        store.dispatch(
          updateMemberCurrentExchange(profile?.currentConversation || null)
        );

        if (profile.account.subscription.lastPayment.hasExpired === false) {
          store.dispatch(updateIsPaymentPendingProcess(false));
        }
        if (profile.member.isBanned) {
          logout();
        }
        if (profile?.currentConversation !== null) {
          store.dispatch(updateOpenDiscussion(true));
        }
        if (
          profile?.currentConversation === null ||
          profile?.currentConversation === undefined
        ) {
          store.dispatch(updateOpenDiscussion(false));
          if (
            location.pathname === routePaths.profileChildren.exchangeAbsolute
          ) {
            navigate(routePaths.profileChildren.homeAbsolute, {
              replace: true,
            });
            false &&
              Toastify(
                `Vous n'avez plus de conversation. Le profil de votre dernier interlocuteur a été supprimé`,
                "info",
                3000
              );
          }
        }
        // window.location.reload();
      });

      socketIO.on(socketChannels.interface_setting_channel, (data) => {
        console.log("||| 🌕 interface", data);
        const interfaceSetting: TInterfaceSettings = data as TInterfaceSettings;

        store.dispatch(
          updateMaxMessage(
            interfaceSetting.moderation.numberMessagePerDiscussion
          )
        );

        store.dispatch(
          updateMaxCaracter(
            interfaceSetting.moderation.numberCaractersPerMessage
          )
        );

        store.dispatch(updateMailMale(interfaceSetting.menGroupHeader.email));

        store.dispatch(
          updateMailFemale(interfaceSetting.womenGroupHeader.email)
        );

        store.dispatch(
          updateGeneralLinks({
            youtube: interfaceSetting.socialMedia.youtube,
            telegram: interfaceSetting.socialMedia.telegram,
            facebook: interfaceSetting.socialMedia.facebook,
            twitter: interfaceSetting.socialMedia.twitter,
            instagram: interfaceSetting.socialMedia.instagram,
          })
        );

        store.dispatch(
          updateIsModerationSuspend(
            interfaceSetting.maintenance.suspendModeration
          )
        );

        store.dispatch(
          updateIsWebsiteMaintenance(interfaceSetting.maintenance.website)
        );

        store.dispatch(
          updateMessageClosedModeration(
            interfaceSetting?.moderation?.schedule || ""
          )
        );
      });

      socketIO.on(socketChannels.notification_channel, (data) => {
        console.log("💦💦💦 notif layout", data);
        callNotificationCount++;
        store.dispatch(updateNbreNotificationSocket(callNotificationCount));
      });

      socketIO.on(socketChannels.member_conversation_messages, (data) => {
        callMessageCount++;
        store.dispatch(updateMessageNotif(callMessageCount));
      });

      socketIO.on(socketChannels.exchange_demand, (data) => {
        callExchangeCount++;
        console.log("💦💦💦 exchange layout", data);
        store.dispatch(updateExchangeNotif(callExchangeCount));
      });

      socketIO.on(socketChannels.new_favorite_notif, (data) => {
        callFavoriteCount++;
        store.dispatch(updateFavoriteNotif(callFavoriteCount));
        console.log(data);
      });

      socketIO.on(socketChannels.delete_favorite_notif, (data) => {
        callFavoriteCount++;
        store.dispatch(updateFavoriteNotif(callFavoriteCount));
        console.log(data);
      });

      socketIO.on(socketChannels.get_live_channel, (data) => {
        callLiveCount++;
        store.dispatch(updateNbreLiveSocket(callLiveCount));
        console.log(data);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    socketIO,
    nbre_message_notif,
    nbre_favorite_notif,
    nbre_exchange_notif,
    nbre_live_notif,
  ]);

  useEffect(() => {
    console.log("me in profile layout");
    if (memberProfileFromStore.member.isBanned) {
      logout();
    }
  }, [logout, memberProfileFromStore.member.isBanned]);

  useEffect(() => {
    initializeParam();
  }, []);

  // useEffect(() => {
  //   let interval = setInterval(() => {
  //     getMeUpdate();
  //     isUserFullAccessAutorized &&
  //       apiPingConnected()
  //         .then((response: any) => {
  //           console.log("😜ping");
  //         })
  //         .catch((error: any) => {
  //           console.log(error);
  //         });
  //   }, 80000);

  //   return () => clearInterval(interval);
  // }, [isUserFullAccessAutorized]);

  return (
    <>
      <div className="l-profile">
        <SideBar />
        <div
          className={classNames("backdrop", { showed: sidebarOpened })}
          onClick={() => {
            dispatch(toggleSidebar());
          }}
        ></div>
        <div className={classnames("profile-content")}>
          <div className="outlet-container">
            <Suspense fallback={<SuspenseFallback />}>
              <Outlet />
            </Suspense>
          </div>
          <Bottombar />
        </div>
      </div>
    </>
  );
};

export default LProfile;
