import React, { createContext, useEffect, useState } from "react";
import { Grow } from "@mui/material";
import "./Blacklist.styles.scss";
import * as _ from "lodash";
import ListManager from "@/components/ListManager/ListManager";
import { TProfileCard } from "../../Elements/ProfileCard/ProfileCard";
import {
  apiGetBlackList,
  apiRemoveFromBlacklist,
} from "../../api/blacklist.api";
import { Toastify } from "@/utils/toast";
import useUserRestrictions from "@/hooks/useUserRestriction";
import UserRestrictionDisplayer from "@/components/UserRestrictionDisplayer/UserRestrictionDisplayer";
import ModalUserRestrictionWhenPaid from "@/components/Modal-user-restriction-when-paid/ModalUserRestrictionWhenPaid";
import { TProfileList } from "../Home-content/Home.content";
import { calculerAge } from "@/utils/calculerAge";
import { TMemberProfile } from "@/features/auth/types";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";

export const BlacklistContext = createContext<{
  removeFromBlacklistFct: (profil_id: string, username: string) => void;
  loadingRemoveB: boolean;
}>({
  removeFromBlacklistFct: () => {},
  loadingRemoveB: false,
});

const CtBlacklist = () => {
  const { isUserFullAccessAutorized } = useUserRestrictions();
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [loading, setLoading] = useState(false);

  const [loadingRemoveB, setLoadingRemoveB] = useState(false);

  const [listeNoire, setListeNoire] = useState<TProfileCard[]>([]);

  useEffect(() => {
    isUserFullAccessAutorized && setLoading(true);

    isUserFullAccessAutorized &&
      apiGetBlackList()
        .then((response: any) => {
          setLoading(false);
          console.log(response);

          let tempList: TProfileList = response.data.result.map((data: any) => {
            return {
              _id: data._id,
              isVerified: data.status.value === "VALIDATED",
              username: data.username,
              type: "",
              progressValue: Math.round(data.rateOfCompletion),
              profil_id: data.memberId,
              isOnline: data?.online,
              age: calculerAge(new Date(data.birthDate)),
              town: data.city,
              country: data.country,
              origine: data.homeCountry,
              nationalite: data.citizenship,
              hasMore: false,
              sendStatut: "",
              description: data.description,
              profile_wanted: data.profilewanted,
              currentConversation: data.currentConversation,
              isListeNoire: true,
              date_of_creation: new Date(data.registrationDate),
              last_connection:
                data.lastLogin !== null ? new Date(data.lastLogin) : null,
              isVisited: data.isVisited,
              isVisitor: data.isVisitor,
              hasSubscription: data.hasSubscription,
              gender:
                memberProfileFromStore.infos.identity.gender === "MALE"
                  ? "FEMALE"
                  : "MALE",
              is_banished: false,
            };
          });

          console.log([...tempList]);
          setListeNoire([...tempList]);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFullAccessAutorized]);

  const removeFromBlacklistFct = (profil_id: string, username: string) => {
    setLoadingRemoveB(true);
    apiRemoveFromBlacklist(profil_id)
      .then((response: any) => {
        setLoadingRemoveB(false);
        console.log(response);

        let tempArr = listeNoire;
        let removedItem = _.remove(tempArr, (x) => {
          return x._id === profil_id;
        });
        console.log(removedItem);
        setListeNoire([...tempArr]);

        Toastify(
          `Vous avez retiré ${username} de votre liste noire avec succès`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingRemoveB(false);

        //Toastify(`${error?.response?.data?.message}`, 'error', 5000);
      });
  };

  return (
    <BlacklistContext.Provider
      value={{
        removeFromBlacklistFct: removeFromBlacklistFct,
        loadingRemoveB: loadingRemoveB,
      }}
    >
      <>
        {isUserFullAccessAutorized ? (
          <div className="ct-blacklist">
            <div className="ct-blacklist__body-part">
              <ListManager
                loading={loading}
                profileList={listeNoire}
                alt={true}
              />
            </div>
          </div>
        ) : (
          <>
            <Grow in={!isUserFullAccessAutorized} mountOnEnter unmountOnExit>
              <div className="ct-blacklist">
                <UserRestrictionDisplayer />
              </div>
            </Grow>
          </>
        )}
        {false && <ModalUserRestrictionWhenPaid />}
      </>
    </BlacklistContext.Provider>
  );
};

export default CtBlacklist;
