import { TProfileCard } from "@/features/profile/Elements/ProfileCard/ProfileCard";
import { InlineIcon } from "@iconify/react";
import {
  Box,
  LinearProgress,
  Grid,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Modal,
  Slide,
  Chip,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Collapse,
} from "@mui/material";
import * as moment from "moment";

import "moment/locale/fr";
import React, { useCallback, useEffect, useState } from "react";
import "./ModalFullProfil.scss";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  apiAskForExchange,
  apiGetFullProfil,
  apiSignalMember,
} from "@/features/profile/api/profile.api";
import { Toastify } from "@/utils/toast";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import {
  selectBodyshape,
  selectEthnies,
} from "@/store/reducers/general_param/general_param.selector";
import { LoadingButton } from "@mui/lab";
import {
  apiAddToFavorite,
  apiRemoveFromFavorite,
} from "@/features/profile/api/favorite.api";
import {
  apiAddToBlacklist,
  apiRemoveFromBlacklist,
} from "@/features/profile/api/blacklist.api";
import { useNavigate } from "react-router-dom";
import { routePaths } from "@/config";
import TextDisplayer from "../TextDisplayer/TextDisplayer";
import { TMemberProfile } from "@/features/auth/types";
import { HighlightOffRounded } from "@mui/icons-material";
import * as yup from "yup";
import { useFormik } from "formik";
import { calculerAge } from "@/utils/calculerAge";
import useUserRestrictions from "@/hooks/useUserRestriction";
import NoData from "../No-data/NoData";

type ModalFullProfilProps = TProfileCard & {
  closeModal: () => void;
  openModal: boolean;
  fromExchange?: boolean;
};
const ModalFullProfil = ({
  _id,
  isVerified,
  isOnline,
  username,
  type,
  progressValue,
  profil_id,
  age,
  town,
  country,
  origine,
  nationalite,
  closeModal,
  openModal,
  date_of_creation,
  last_connection,
  hasMore,
  isListeNoire,
  isSimpleFavorite,
  isMutualFavorite,
  refreshHome,
  fromFan,
  fromExchange = false,
  hasSubscription,
}: ModalFullProfilProps) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;
  const ethnies = useSelector(selectEthnies);
  const bodyshapeFromstore = useSelector(selectBodyshape);

  const theme = useTheme();
  const matches_xl = useMediaQuery(theme.breakpoints.down("xl"));

  const [toogleOption, setToogleOption] = useState(true);
  const handleToogleOption = () => setToogleOption((prev) => !prev);

  const [isFavorite, setIsFavorite] = useState(isSimpleFavorite);
  const [isBlackList, setIsBlacklist] = useState(isListeNoire);
  const [isMutual, setIsMutual] = useState(isMutualFavorite);
  const [isFromNotification, setIsFromNotification] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [expandedProfil, setExpandedProfil] = useState(true);
  const [expandedReligion, setExpandedReligion] = useState(true);
  const [expandedPratique, setExpandedPratique] = useState(true);
  const [expandedSante, setExpandedSante] = useState(true);
  const toogleProfil = () => setExpandedProfil(!expandedProfil);
  const toogleReligion = () => setExpandedReligion(!expandedReligion);
  const tooglePratique = () => setExpandedPratique(!expandedPratique);
  const toogleSante = () => setExpandedSante(!expandedSante);

  const hasNoAbonnement = useCallback((): boolean => {
    return hasSubscription !== undefined && !hasSubscription;
  }, [hasSubscription]);

  const navigate = useNavigate();

  const { isUserFullAccessAutorized } = useUserRestrictions();

  const [profilSelected, setProfilSelected] = useState<TMemberProfile | null>(
    null
  );

  const [openSignal, setOpenSignal] = useState(false);
  const [openConfirmedSignal, setOpenConfirmedSignal] = useState(false);
  const handleOpenSignal = () => setOpenSignal(true);
  const handleCloseSignal = () => {
    formik.resetForm();
    setOpenSignal(false);
  };

  const handleOpenConfirmedSignal = () => setOpenConfirmedSignal(true);
  const handleCloseConfirmedSignal = () => setOpenConfirmedSignal(false);

  const validationSchema = yup.object({
    motif: yup.string().required(" la raison est obligatoire "),
  });

  const formik = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleOpenConfirmedSignal();
      }, 500);
    },
  });

  const tenue_vestimentaire_femmeArrayManager = (
    not_wear_headscarf: boolean,
    wear_turban: boolean,
    wear_hijab: boolean,
    wear_jilbab: boolean,
    wear_niqab: boolean,
    wear_abaya: boolean
  ): string[] => {
    let result: string[] = [];

    not_wear_headscarf && result.push("Ne porte pas encore le voile");
    wear_turban && result.push("Porte le turban");
    wear_hijab && result.push("Porte le Hijab");
    wear_jilbab && result.push("Porte le Jilbab");
    wear_niqab && result.push("Porte le Niqab");
    wear_abaya && result.push("L'Abaya");

    return result;
  };

  const goodNullBooleanValueReverse = (val: any): string => {
    switch (val) {
      case null:
        return "Pas encore répondu";
      case true:
        return "Oui";
      case false:
        return "Non";
      default:
        return "Pas encore répondu";
    }
  };

  useEffect(() => {
    setLoading(true);

    if (_id?.length === 6) {
      setIsFromNotification(true);
    }

    apiGetFullProfil(_id || "")
      .then((response: any) => {
        setLoading(false);
        console.log(response);
        const profilConnectedTemp = response.data as TMemberProfile;
        setProfilSelected({ ...profilConnectedTemp });
        refreshHome?.("visited");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        false &&
          error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
        false && closeModal();
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToFavorite = () => {
    setLoading1(true);
    apiAddToFavorite(_id || "")
      .then((response: any) => {
        setLoading1(false);
        setIsFavorite(true);
        setIsMutual(true);
        console.log(response);
        Toastify(`${username} a été rajouté à vos favoris`, "success", 5000);
        refreshHome?.("add-favorite");
        console.log("after-add", isMutual);
        if (fromFan && isMutual) {
          setIsFavorite(false);
        }
      })
      .catch((error) => {
        setLoading1(false);
      });
  };

  const removeFromFavorite = () => {
    setLoading1(true);
    apiRemoveFromFavorite(_id || "")
      .then((response: any) => {
        setLoading1(false);
        setIsFavorite(false);
        setIsMutual(false);
        console.log(response);
        Toastify(
          `Vous avez retiré ${username} de vos favoris avec succès`,
          "success",
          5000
        );
        refreshHome?.("remove-favorite");
        if (window.location.pathname === "/profile/favorite" && !fromFan) {
          closeModal();
        }
        console.log("after-remove", isMutual);
        if (fromFan && isMutual) {
          setIsFavorite(true);
        }
      })
      .catch((error: any) => {
        setLoading1(false);
      });
  };

  const addToBlacklist = () => {
    setLoading2(true);

    apiAddToBlacklist(_id || "")
      .then((response: any) => {
        setLoading2(false);
        setIsBlacklist(true);
        Toastify(
          `${username} a été rajouté à votre liste noire`,
          "success",
          5000
        );
        refreshHome?.("add-blacklist");
        closeModal();
      })
      .catch((error) => {
        setLoading2(false);
      });
  };

  const removeFromBlacklist = () => {
    setLoading2(true);
    apiRemoveFromBlacklist(_id || "")
      .then((response: any) => {
        setLoading2(false);
        setIsBlacklist(false);
        Toastify(
          `Vous avez retiré ${username} de votre liste noire avec succès`,
          "success",
          5000
        );
        refreshHome?.("remove-blacklist");
      })
      .catch((error) => {
        setLoading2(false);
      });
  };

  const startExchange = () => {
    setLoading3(true);

    apiAskForExchange(_id || "")
      .then((response: any) => {
        setLoading3(false);
        console.log(response);
        Toastify(
          ` votre demande d'échange a bien été envoyé à ${username}`,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoading3(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  const signalMember = () => {
    setLoading2(true);

    apiSignalMember({ member_id: _id || "", note: formik.values.motif })
      .then((response: any) => {
        setLoading2(false);
        console.log(response);
        Toastify(
          ` Le signalement de ${username} a bien été envoyé à nos équipes de modération `,
          "success",
          5000
        );

        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      })
      .catch((error) => {
        setLoading2(false);
        formik.resetForm();
        handleCloseSignal();
        handleCloseConfirmedSignal();
      });
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openModal} mountOnEnter unmountOnExit>
          <div className="modal-full-profile-container">
            <div className="modal-full-profile-container__title-part">
              <IconButton
                className="back-icon"
                size="medium"
                color="secondary"
                onClick={closeModal}
              >
                <InlineIcon icon="tabler:chevron-left" />
              </IconButton>
              <div className="title-infos-username-age">
                <div className="username-age">
                  <p
                    className="username"
                    title={username || profilSelected?.member.username}
                  >
                    {username || profilSelected?.member.username}
                  </p>
                  {age ||
                    (profilSelected?.infos.identity.birthDate && (
                      <p className="age">
                        |{" "}
                        {age || profilSelected?.infos.identity.birthDate
                          ? calculerAge(
                              new Date(
                                profilSelected?.infos.identity.birthDate || ""
                              )
                            )
                          : ""}
                         <small>ans</small>
                      </p>
                    ))}
                </div>

                <div
                  className={`dot  ${isOnline || profilSelected?.member.online?.isOnline ? "" : "offline"}`}
                ></div>
              </div>
              <IconButton
                className="close-icon"
                size="medium"
                color="secondary"
                onClick={closeModal}
              >
                <InlineIcon icon="iconamoon:close-bold" />
              </IconButton>
              {matches_xl && !isFromNotification && profilSelected !== null && (
                <Button
                  className="toogle-action-btn"
                  size="medium"
                  color="tertiary"
                  onClick={handleToogleOption}
                  startIcon={
                    <InlineIcon
                      icon={
                        toogleOption
                          ? "line-md:chevron-up-circle"
                          : "line-md:chevron-down-circle"
                      }
                    />
                  }
                >
                  {toogleOption
                    ? "Masquer les actions"
                    : "Afficher les actions"}
                </Button>
              )}
            </div>
            {isUserFullAccessAutorized && profilSelected !== null && (
              <>
                <div className="modal-full-profile-container__actions-part">
                  <Collapse
                    in={toogleOption}
                    orientation="vertical"
                    mountOnEnter
                    unmountOnExit
                    style={{ zIndex: 10 }}
                  >
                    <div className="layout-part">
                      {!fromExchange && !isFromNotification && (
                        <>
                          <Chip
                            className="action-chip"
                            color="primary"
                            variant="filled"
                            size="medium"
                            label="Demander en échange"
                            icon={
                              <InlineIcon
                                icon={
                                  loading3
                                    ? "eos-icons:three-dots-loading"
                                    : "fluent-emoji-high-contrast:handshake"
                                }
                              />
                            }
                            onClick={() => {
                              !loading3 && startExchange();
                            }}
                          />
                          {!hasNoAbonnement() && (
                            <>
                              {fromFan && !isMutual ? (
                                <Chip
                                  className="action-chip"
                                  color={!isFavorite ? "warning" : "primary"}
                                  variant="filled"
                                  size="medium"
                                  label={
                                    !isFavorite
                                      ? "Retirer des favoris"
                                      : "Ajouter en favoris"
                                  }
                                  icon={
                                    <InlineIcon
                                      icon={
                                        loading1
                                          ? "eos-icons:three-dots-loading"
                                          : !isFavorite
                                            ? "iconamoon:star-off-duotone"
                                            : "iconamoon:star-duotone"
                                      }
                                    />
                                  }
                                  onClick={() => {
                                    if (!isFavorite) {
                                      !loading1 && removeFromFavorite();
                                    } else {
                                      !loading1 && addToFavorite();
                                    }
                                  }}
                                />
                              ) : (
                                <Chip
                                  className="action-chip"
                                  color={isFavorite ? "warning" : "primary"}
                                  variant="filled"
                                  size="medium"
                                  label={
                                    isFavorite
                                      ? "Retirer des favoris"
                                      : "Ajouter en favoris"
                                  }
                                  icon={
                                    <InlineIcon
                                      icon={
                                        loading1
                                          ? "eos-icons:three-dots-loading"
                                          : isFavorite
                                            ? "iconamoon:star-off-duotone"
                                            : "iconamoon:star-duotone"
                                      }
                                    />
                                  }
                                  onClick={() => {
                                    if (isFavorite) {
                                      !loading1 && removeFromFavorite();
                                    } else {
                                      !loading1 && addToFavorite();
                                    }
                                  }}
                                />
                              )}
                            </>
                          )}

                          {!hasNoAbonnement() && (
                            <Chip
                              className="action-chip"
                              color={isBlackList ? "warning" : "primary"}
                              variant="filled"
                              size="medium"
                              label={
                                isBlackList
                                  ? " Retirer de la liste noire"
                                  : "Ajouter à la liste noire"
                              }
                              icon={
                                <InlineIcon
                                  icon={
                                    loading2
                                      ? "eos-icons:three-dots-loading"
                                      : isBlackList
                                        ? "tabler:user-x"
                                        : "tabler:user-off"
                                  }
                                />
                              }
                              onClick={() => {
                                if (isBlackList) {
                                  !loading2 && removeFromBlacklist();
                                } else {
                                  !loading2 && addToBlacklist();
                                }
                              }}
                            />
                          )}
                        </>
                      )}

                      {!isFromNotification && !hasNoAbonnement() && (
                        <Chip
                          className="action-chip "
                          color="primary"
                          variant="filled"
                          size="medium"
                          label="Signaler le profil"
                          icon={<InlineIcon icon={"tabler:alert-circle"} />}
                          onClick={handleOpenSignal}
                        />
                      )}
                    </div>
                  </Collapse>
                </div>
              </>
            )}

            <div className="modal-full-profile-container__body-part">
              {loading ? (
                <div className="modal-loader-container">
                  <img
                    src={require("@/assets/images/png/logo-mini.png")}
                    className="logo-mini"
                    alt="load-profil"
                  />
                  <img
                    src={require("@/assets/images/gif/loadmore.gif")}
                    className="load-profil"
                    alt="load-profil"
                  />
                  <small> Chargement du profil </small>
                </div>
              ) : (
                <>
                  {profilSelected === null ? (
                    <div className="modal-loader-container">
                      <img
                        src={require("@/assets/images/png/logo-mini.png")}
                        className="logo-mini"
                        alt="load-profil"
                      />
                      <NoData />
                      <small style={{ fontSize: "1rem", textAlign: "center" }}>
                        {" "}
                        Ce profil ne peut être affiché.{" "}
                      </small>
                    </div>
                  ) : (
                    <div className="full-profile-card">
                      <div className={"full-profile-card__upper"}>
                        {hasNoAbonnement() && (
                          <p
                            className="detail-text-part"
                            style={{
                              color: "var(--ui-red)",
                              marginTop: "10px",
                            }}
                          >
                            {" "}
                            <InlineIcon icon="mdi:account-payment" />{" "}
                            &nbsp;&nbsp;
                            <b>
                              Ce profil n'a pas d'abonnement, mais vous pouvez
                              lui envoyer une demande d'échange.
                            </b>{" "}
                          </p>
                        )}
                        <div
                          className={`pastille ${isVerified || profilSelected?.status.value === "VALIDATED" ? "" : "ko"}`}
                        >
                          <InlineIcon
                            icon={
                              isVerified ||
                              profilSelected?.status.value === "VALIDATED"
                                ? "humbleicons:verified"
                                : "ep:close-bold"
                            }
                          />
                        </div>
                        <Box sx={{ width: "100%", marginTop: "10px" }}>
                          <LinearProgress
                            variant="determinate"
                            color={
                              (progressValue ||
                                profilSelected?.rateOfCompletion) === 0
                                ? "inherit"
                                : "success"
                            }
                            value={
                              progressValue || profilSelected?.rateOfCompletion
                            }
                            sx={{ borderRadius: 5 }}
                          />
                          {(progressValue ||
                            profilSelected?.rateOfCompletion) === 0 ? (
                            <p className="profil-progress-text ko">
                              {" "}
                              Profil rempli à{" "}
                              <b>
                                {progressValue ||
                                  Math.floor(
                                    profilSelected?.rateOfCompletion || 0
                                  )}
                                %
                              </b>{" "}
                            </p>
                          ) : (
                            <p className="profil-progress-text ">
                              {" "}
                              Profil rempli à{" "}
                              <b>
                                {progressValue ||
                                  Math.floor(
                                    profilSelected?.rateOfCompletion || 0
                                  )}
                                %
                              </b>{" "}
                            </p>
                          )}
                        </Box>
                        <div className="modal-tag-info-part">
                          <p className="profil-id-text">
                            {" "}
                            ID: <b>{profil_id}</b>{" "}
                          </p>
                          <div className="tag">
                            <InlineIcon icon="el:globe" />
                            {origine ||
                            profilSelected?.infos.profile.homeCountry ? (
                              <p>
                                <span>Origine</span>{" "}
                                <b
                                  title={
                                    origine ||
                                    profilSelected?.infos.profile.homeCountry
                                  }
                                >
                                  {origine ||
                                    profilSelected?.infos.profile.homeCountry}
                                </b>
                              </p>
                            ) : (
                              <p> -- </p>
                            )}
                          </div>
                          <div className="tag">
                            <InlineIcon icon="icon-park-outline:passport" />
                            {nationalite ||
                            profilSelected?.infos.profile.citizenship ? (
                              <p>
                                <span>Nationalité</span>{" "}
                                <b
                                  title={
                                    nationalite ||
                                    profilSelected?.infos.profile.citizenship
                                  }
                                >
                                  {nationalite ||
                                    profilSelected?.infos.profile.citizenship}
                                </b>{" "}
                              </p>
                            ) : (
                              <p> -- </p>
                            )}
                          </div>
                          <div className="tag">
                            <InlineIcon icon="akar-icons:location" />
                            <p
                              title={
                                town ||
                                profilSelected?.infos.identity.city +
                                  "•" +
                                  country ||
                                profilSelected?.infos.identity.country
                              }
                            >
                              {town ||
                                profilSelected?.infos.identity.city ||
                                "--"}{" "}
                              ,{" "}
                              {country ||
                                profilSelected?.infos.identity.country ||
                                "--"}
                            </p>
                          </div>
                          <div className="date-part">
                            <p className="profil-date-text">
                              {" "}
                              Inscrit depuis le{" "}
                              <b>
                                {moment
                                  .default(
                                    new Date(
                                      profilSelected?.registrationDate ||
                                        date_of_creation! ||
                                        ""
                                    )
                                  )
                                  .format("L")}
                              </b>{" "}
                            </p>
                            <p className="profil-date-text">
                              {last_connection! ||
                              profilSelected?.member.online?.lastConnexion ? (
                                <>
                                  {" "}
                                  Dernière connexion le{" "}
                                  <b>
                                    {moment
                                      .default(
                                        new Date(
                                          last_connection! ||
                                            profilSelected?.member.online
                                              ?.lastConnexion ||
                                            ""
                                        )
                                      )
                                      .format("L")}
                                  </b>{" "}
                                </>
                              ) : (
                                <>{/* Pas encore connecté */}</>
                              )}{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* FICHE PROFIL */}
                      <Accordion
                        expanded={expandedProfil}
                        onChange={toogleProfil}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className="accordion-title-container">
                            <InlineIcon icon="carbon:user-profile-alt" />
                            <Typography> Profil </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="fiche-container">
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Origine principale :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.homeCountry || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> 2nd Origine :</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.otherHomeCountry || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Nationalité :</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.citizenship || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      2nd Nationalité :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.otherCitizenship || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Ethnie :</p>
                                    <p className="value-text">
                                      {" "}
                                      {ethnies.find(
                                        (x) =>
                                          x.id ===
                                          profilSelected?.infos?.profile
                                            ?.ethnicity
                                      )?.name || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Situation matrimoniale :
                                    </p>
                                    <p className="value-text">
                                      {profilSelected?.infos?.profile
                                        ?.maritalStatus === "NEVER_MARRIED" &&
                                        "Jamais Marié(e)"}
                                      {profilSelected?.infos?.profile
                                        ?.maritalStatus === "DIVORCED" &&
                                        "Divorcé(e)"}
                                      {profilSelected?.infos?.profile
                                        ?.maritalStatus === "WIDOW" &&
                                        "Veuf(Veuve)"}
                                      {profilSelected?.infos?.profile
                                        ?.maritalStatus === "MARRIED" &&
                                        "Marié(e)"}
                                    </p>
                                  </div>
                                </Grid>
                                {profilSelected?.infos?.identity?.gender ===
                                  "MALE" &&
                                  profilSelected?.infos?.profile
                                    ?.maritalStatus === "MARRIED" && (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <div className="item-infos">
                                          <p className="field-text">
                                            {" "}
                                            Ai-je les moyens financiers pour un
                                            second foyer ?
                                          </p>
                                          <p className="value-text">
                                            {goodNullBooleanValueReverse(
                                              profilSelected?.infos?.profile
                                                ?.haveMoney
                                            )}
                                          </p>
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <div className="item-infos">
                                          <p className="field-text">
                                            {" "}
                                            Ma famille sera-t'elle mise au
                                            courant avant le mariage ?
                                          </p>
                                          <p className="value-text">
                                            {goodNullBooleanValueReverse(
                                              profilSelected?.infos?.profile
                                                ?.isFamilyKnowBeforeMarriage
                                            )}
                                          </p>
                                        </div>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        lg={12}
                                        xl={12}
                                      >
                                        <div className="item-infos">
                                          <p className="field-text">
                                            {" "}
                                            Serai-je équitable dans le partage
                                            des nuits ?
                                          </p>
                                          <p className="value-text">
                                            {goodNullBooleanValueReverse(
                                              profilSelected?.infos?.profile
                                                ?.isFairInSharingNights
                                            )}
                                          </p>
                                        </div>
                                      </Grid>
                                    </>
                                  )}

                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Ethnie(s) recherchée(s) :
                                    </p>
                                    <p className="value-text">
                                      {profilSelected?.infos?.profile?.ethnicitiesWanted
                                        .map((elt) => {
                                          return ethnies.find(
                                            (x) => x.id === elt
                                          )?.name;
                                        })
                                        .join(", ") || ""}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Lieu(x) où vous êtes né(e) et où vous avez
                                      grandi :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.childhoodPlace || ""}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Garçon(s) :</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.dependentBoys || 0}{" "}
                                      Garçon(s)
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Fille(s) :</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.dependentGirls || 0}{" "}
                                      Fille(s)
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Garde des enfants en cas de Hijra :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.hijraChildren || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Nombre d'enfants acceptés :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.acceptableNbChildren || 0}{" "}
                                      enfant(s)
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Profession :</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.profile
                                        ?.occupation || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Taille :</p>

                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.profile
                                          ?.height === 0 ||
                                        profilSelected?.infos?.profile
                                          ?.height === undefined
                                          ? "Ne souhaite pas se prononcer"
                                          : `${profilSelected?.infos?.profile?.height} cm`
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Corpulence :</p>

                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.profile
                                          ?.fatness === "" ||
                                        profilSelected?.infos?.profile
                                          ?.fatness === null
                                          ? "Ne souhaite pas se prononcer"
                                          : bodyshapeFromstore.find(
                                              (x) =>
                                                x.id ===
                                                profilSelected?.infos?.profile
                                                  ?.fatness
                                            )?.name || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Description :</p>

                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.profile
                                          ?.description || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Profil recherché :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.profile
                                          ?.profilewanted || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Critères rédhibitoires :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.profile
                                          ?.prohibitiveCriteria || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* End od PROFIL PART */}

                      {/* FICHE RELIGION */}
                      <Accordion
                        expanded={expandedReligion}
                        onChange={toogleReligion}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className="accordion-title-container">
                            <InlineIcon icon="healthicons:mosque-outline" />
                            <Typography> Religion </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="fiche-container">
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Salafi :</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousAspects
                                        ?.isSalafi
                                        ? "Oui"
                                        : "Non" || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Tenue vestimentaire :
                                    </p>
                                    {profilSelected?.infos?.identity?.gender ===
                                      "MALE" && (
                                      <p className="value-text">
                                        {" "}
                                        {profilSelected?.infos?.religiousAspects
                                          ?.maleDressCode || ""}{" "}
                                      </p>
                                    )}
                                    {profilSelected?.infos?.identity?.gender ===
                                      "FEMALE" && (
                                      <p className="value-text">
                                        {" "}
                                        {tenue_vestimentaire_femmeArrayManager(
                                          profilSelected?.infos
                                            ?.religiousAspects?.femaleDressCode
                                            ?.notWearHeadscarf,
                                          profilSelected?.infos
                                            ?.religiousAspects?.femaleDressCode
                                            ?.wearTurban,
                                          profilSelected?.infos
                                            ?.religiousAspects?.femaleDressCode
                                            ?.wearHijab,
                                          profilSelected?.infos
                                            ?.religiousAspects?.femaleDressCode
                                            ?.wearJilbab,
                                          profilSelected?.infos
                                            ?.religiousAspects?.femaleDressCode
                                            ?.wearNiqab,
                                          profilSelected?.infos
                                            ?.religiousAspects?.femaleDressCode
                                            ?.wearAbaya
                                        ).join(", ") || ""}{" "}
                                      </p>
                                    )}
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Les savants que je suis :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.religiousAspects
                                          ?.favoriteScholars || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Les prédicateurs que j'écoute :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={7}
                                      value={
                                        profilSelected?.infos?.religiousAspects
                                          ?.favoritePreachers || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      École de jurisprudence :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousAspects
                                        ?.haveSchoolOfJurisprudence || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Hijra:</p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousAspects
                                        ?.isInHijra
                                        ? "Oui"
                                        : "Non" || ""}{" "}
                                      ,{" "}
                                      {profilSelected?.infos?.religiousAspects
                                        ?.hijraCountry || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Je souhaite faire la hijra :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousAspects
                                        ?.whenToMakeHijra || "jamais"}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                {profilSelected?.infos?.identity?.gender ===
                                  "FEMALE" && (
                                  <>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                      <div className="item-infos">
                                        <p className="field-text">
                                          {" "}
                                          J'accepte les hommes mariés:
                                        </p>
                                        <p className="value-text">
                                          {" "}
                                          {profilSelected?.infos
                                            ?.religiousAspects?.acceptMarriedMan
                                            ? "Oui"
                                            : "Non" || ""}{" "}
                                        </p>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={12} xl={12}>
                                      <div className="item-infos">
                                        <p className="field-text">
                                          {" "}
                                          J'ai un tuteur:
                                        </p>
                                        <p className="value-text">
                                          {" "}
                                          {profilSelected?.infos
                                            ?.religiousAspects?.haveMahram
                                            ? "Oui"
                                            : "Non" || ""}{" "}
                                        </p>
                                      </div>
                                    </Grid>
                                  </>
                                )}
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      J'accepte de déménager :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousAspects
                                        ?.agreeToMove
                                        ? "Oui"
                                        : "Non" || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* End od RELIGION PART */}

                      {/* FICHE PRATIQUE */}
                      <Accordion
                        expanded={expandedPratique}
                        onChange={tooglePratique}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className="accordion-title-container">
                            <InlineIcon icon="clarity:clipboard-line" />
                            <Typography> Pratique </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="fiche-container">
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Les (05)cinq prières :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.prayFivePrayers
                                        ? "Oui"
                                        : "Non" || ""}{" "}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Prière du vendredi à la mosquée :
                                    </p>
                                    <p className="value-text">
                                      {" "}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.prayFridayMosque
                                        ? "Oui"
                                        : "Non" || ""}
                                    </p>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Rapport à la mosquée:
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={4}
                                      value={
                                        profilSelected?.infos
                                          ?.religiousPractices
                                          ?.relationshipToMosque || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Rapport à la lecture du Qour’an El Karim :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={4}
                                      value={
                                        profilSelected?.infos
                                          ?.religiousPractices
                                          ?.relationshipToQuranReading || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Rapport à la langue arabe :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={4}
                                      value={
                                        profilSelected?.infos
                                          ?.religiousPractices
                                          ?.relationshipToArabicLanguage || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Niveau d’instruction religieuse:
                                    </p>
                                    <p className="value-text">
                                      {profilSelected?.infos?.religiousPractices
                                        ?.levelOfReligiousEducation ===
                                        "NONE" && "Aucun"}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.levelOfReligiousEducation ===
                                        "BEGINNER" && "Débutant(e)"}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.levelOfReligiousEducation ===
                                        "INTERMEDIATE" && "Intermédiaire"}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.levelOfReligiousEducation ===
                                        "ADVANCED" && "Avancé(e)"}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.levelOfReligiousEducation ===
                                        "SENIOR" && "Confirmé(e))"}
                                      {profilSelected?.infos?.religiousPractices
                                        ?.levelOfReligiousEducation ===
                                        "NOT_PRONOUNCED" &&
                                        "Ne se prononce pas"}
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* End od PRATIQUE PART */}

                      {/* FICHE SANTE */}
                      <Accordion
                        expanded={expandedSante}
                        onChange={toogleSante}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className="accordion-title-container">
                            <InlineIcon icon="teenyicons:plant-outline" />
                            <Typography> Santé </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="fiche-container">
                            <Box>
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text">
                                      {" "}
                                      Santé physique :
                                    </p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={4}
                                      value={
                                        profilSelected?.infos
                                          ?.healthAndDisability
                                          ?.physicalHealth || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={12} xl={12}>
                                  <div className="item-infos">
                                    <p className="field-text"> Mal occulte :</p>
                                    <TextDisplayer
                                      className="value-text"
                                      rows={4}
                                      value={
                                        profilSelected?.infos
                                          ?.healthAndDisability
                                          ?.occultDiseases || ""
                                      }
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </Box>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      {/* End od SANTE PART */}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Slide>
      </Modal>

      {/* SIGNALER  DIALOG PART */}
      <Dialog
        open={openConfirmedSignal}
        onClose={handleCloseConfirmedSignal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Signalement"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Attention!!!</b> cette action est irréversible.
            <br />
            Vous êtes sur le point de <b>signaler</b> ce profil. Êtes-vous
            sûr(e) de vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmedSignal}>Annuler</Button>
          <LoadingButton
            id="id-signal-btn"
            loading={loading2}
            loadingPosition="center"
            onClick={signalMember}
            autoFocus
          >
            Signaler
          </LoadingButton>
        </DialogActions>
      </Dialog>
      {/* Ennd of SIGNALER  DIALOG PART */}
      {/* SIGNAL */}
      <Modal
        open={openSignal}
        onClose={handleCloseSignal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <form className="form-raison-part" onSubmit={formik.handleSubmit}>
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseSignal}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison du signalement"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formik.handleChange}
              value={formik.values.motif}
              error={formik.touched.motif && Boolean(formik.errors.motif)}
              helperText={
                formik.touched.motif && (formik.errors.motif as string)
              }
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    SIGNALER{" "}
                  </LoadingButton>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseSignal}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      </Modal>
      {/* End of SIGNAL */}
    </>
  );
};

export default ModalFullProfil;
